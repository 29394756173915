<template>
  <ConfigureWidgetStyled>
    <HeaderStyled>
      <button :class="{ active: !showWidgetOptions }" @click="showWidgetOptions = false">
        {{ $t('titles.widgetType') }}
      </button>
      <button :class="{ active: showWidgetOptions }" :disabled="!selectedWidget" @click="showWidgetOptions = true">
        {{ $t('titles.widgetOptions') }}
      </button>
    </HeaderStyled>
    <transition-group tag="main" :name="transitionName">
      <WidgetsAvailable
        v-if="!showWidgetOptions"
        :key="'widgets-available'"
        :selectedWidget="selectedWidget"
        :widgetsAvailable="widgetsAvailable"
        :dimensionsSelected="dimensionsSelected"
        @setWidgetType="$emit('setWidgetType', $event)"
      />
      <WidgetOptions
        v-else
        :key="'widget-options'"
        :title="title"
        :selectedWidget="selectedWidget"
        :widgetSettings="widgetSettings"
        :dimensionsSelected="dimensionsSelected"
        @update:title="$emit('update:title', $event)"
        @changeWidgetSettings="$emit('changeWidgetSettings', $event)"
      />
    </transition-group>
  </ConfigureWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter, buttonReset } from '@styles/mixins'

import WidgetsAvailable from './WidgetsAvailable'
import WidgetOptions from './WidgetOptions'

const ConfigureWidgetStyled = styled('div')`
  grid-area: configure-widget;
  margin: 0.5rem;
  padding: 0.5rem;
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  border-radius: 0.5rem;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-template-rows: 3.5rem 1fr;
  grid-template-areas:
    'header'
    'main';

  > main {
    grid-area: main;
    position: relative;
    overflow: auto;
    .fade-in-left-leave-active,
    .fade-in-right-leave-active {
      position: absolute;
    }
  }
`

const HeaderStyled = styled('div')`
  ${flexCenter}
  button {
    ${buttonReset}
    text-transform: uppercase;
    color: ${p => p.theme.colors.primary};
    border-bottom: 3px solid transparent;
    padding: 0.5rem;
    margin: 1rem;
    transition: border-color 0.25s linear;
    &:not([disabled]):hover {
      color: ${p => (p.theme.isDark ? p.theme.colors.navFontNormal : p.theme.colors.secondary)};
      border-color: ${p => (p.theme.isDark ? p.theme.colors.navFontNormal : p.theme.colors.secondary)};
    }
    &.active,
    &.active:hover {
      color: ${p => p.theme.colors.primary};
      border-color: ${p => p.theme.colors.primary};
    }
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    widgetsAvailable: {
      type: Array,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
    widgetSettings: {
      type: Object,
    },
  },
  components: {
    HeaderStyled,
    ConfigureWidgetStyled,
    WidgetsAvailable,
    WidgetOptions,
  },
  data() {
    return {
      showWidgetOptions: false,
    }
  },
  computed: {
    transitionName() {
      // return this.showWidgetOptions ? 'fade-in-right' : 'fade-in-left'
      return 'fade-in-right'
    },
  },
  watch: {
    selectedWidget: {
      handler(w) {
        if (!w) {
          this.showWidgetOptions = false
        }
      },
    },
  },
}
</script>
