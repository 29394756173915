var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ConfigureWidgetStyled', [_c('HeaderStyled', [_c('button', {
    class: {
      active: !_vm.showWidgetOptions
    },
    on: {
      "click": function click($event) {
        _vm.showWidgetOptions = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('titles.widgetType')) + " ")]), _c('button', {
    class: {
      active: _vm.showWidgetOptions
    },
    attrs: {
      "disabled": !_vm.selectedWidget
    },
    on: {
      "click": function click($event) {
        _vm.showWidgetOptions = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('titles.widgetOptions')) + " ")])]), _c('transition-group', {
    attrs: {
      "tag": "main",
      "name": _vm.transitionName
    }
  }, [!_vm.showWidgetOptions ? _c('WidgetsAvailable', {
    key: 'widgets-available',
    attrs: {
      "selectedWidget": _vm.selectedWidget,
      "widgetsAvailable": _vm.widgetsAvailable,
      "dimensionsSelected": _vm.dimensionsSelected
    },
    on: {
      "setWidgetType": function setWidgetType($event) {
        return _vm.$emit('setWidgetType', $event);
      }
    }
  }) : _c('WidgetOptions', {
    key: 'widget-options',
    attrs: {
      "title": _vm.title,
      "selectedWidget": _vm.selectedWidget,
      "widgetSettings": _vm.widgetSettings,
      "dimensionsSelected": _vm.dimensionsSelected
    },
    on: {
      "update:title": function updateTitle($event) {
        return _vm.$emit('update:title', $event);
      },
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.$emit('changeWidgetSettings', $event);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }