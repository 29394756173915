<template>
  <WidgetOptionsStyled>
    <div class="row">
      <div class="title">
        <div class="label">
          {{ $tc('title', 1) }}
        </div>
        <input :value="title" @keyup="handleKeyUp" />
        <ClearTitleStyled v-if="title" @click="$emit('update:title', '')">
          <XSquareIcon />
        </ClearTitleStyled>
      </div>
    </div>
    <WidgetSettingsStyled v-if="hasWidgetSettings">
      <WidgetSettings
        :widgetSettings="widgetSettings"
        :widget="selectedWidget"
        :dimensionsSelected="dimensionsSelected"
        @changeWidgetSettings="$emit('changeWidgetSettings', $event)"
      />
    </WidgetSettingsStyled>
  </WidgetOptionsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { XSquareIcon } from 'vue-feather-icons'

import chroma from 'chroma-js'

import { flexCenter, buttonReset } from '@styles/mixins'
import WidgetSettings from './WidgetOptions/WidgetSettings'

const WidgetOptionsStyled = styled('div')`
  grid-area: widget-options;
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-template-areas:
    'title'
    'settings';

  .row {
    grid-area: title;
    ${flexCenter}
  }

  .title {
    ${flexCenter}
    flex-grow: 1;
    position: relative;
    box-shadow: ${({ theme }) => theme.colors.widgetShadow};
    .label {
      ${flexCenter}
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.darkGrey : theme.solidBG)};
      height: 1.5rem;
      padding: 0.25rem 0.5rem;
      text-transform: capitalize;
    }
    input {
      flex-grow: 1;
      padding: 0;
      padding-left: 0.5rem;
      height: 2rem;
      border: none;
      color: ${p => (p.theme.isDark ? p.theme.colors.navFontNormal : p.theme.colors.primary)};
      background: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.2).css()};
      &:focus {
        outline: none;
        border: none;
      }
      ::placeholder {
        color: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.7).css()};
      }
    }
  }
`

const WidgetSettingsStyled = styled('div')`
  grid-area: settings;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.darkerGrey : theme.colors.white)};
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const ClearTitleStyled = styled('div')`
  ${buttonReset}
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    selectedWidget: {
      type: Object,
    },
    widgetSettings: {
      type: Object,
    },
    dimensionsSelected: {
      type: Array,
    },
  },
  components: {
    WidgetOptionsStyled,
    ClearTitleStyled,
    XSquareIcon,
    WidgetSettingsStyled,
    WidgetSettings,
  },
  data() {
    return {
      allowedWidgets: [
        'CYCLE_TABLE',
        'HISTOGRAM',
        'DASHBOARD_INFORMATION',
        'PIVOT_TABLE',
        'ERROR_STATISTICS',
        'ERROR_LOG',
        'TABLE',
        'DRILLING_TABLE',
      ],
    }
  },
  computed: {
    hasWidgetSettings() {
      return this.allowedWidgets.includes(get(this.selectedWidget, 'name', ''))
    },
  },
  methods: {
    handleKeyUp(e) {
      this.$emit('update:title', e.target.value)
    },
  },
}
</script>
