var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WidgetOptionsStyled', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('title', 1)) + " ")]), _c('input', {
    domProps: {
      "value": _vm.title
    },
    on: {
      "keyup": _vm.handleKeyUp
    }
  }), _vm.title ? _c('ClearTitleStyled', {
    on: {
      "click": function click($event) {
        return _vm.$emit('update:title', '');
      }
    }
  }, [_c('XSquareIcon')], 1) : _vm._e()], 1)]), _vm.hasWidgetSettings ? _c('WidgetSettingsStyled', [_c('WidgetSettings', {
    attrs: {
      "widgetSettings": _vm.widgetSettings,
      "widget": _vm.selectedWidget,
      "dimensionsSelected": _vm.dimensionsSelected
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.$emit('changeWidgetSettings', $event);
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }